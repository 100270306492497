import React from 'react';

const Regs = () => (
  <div style={{ color: "white", textAlign: "center" }}>
    <p>Betfair Pty Limited is licensed and regulated by the Northern Territory Government of Australia.</p>
    <p>
      Betfair Pty Limited&apos;s gambling operations are governed by its&nbsp;
      <a  className="rg-link" href="https://www.betfair.com.au/info/responsible-gambling/" rel="noopener noreferrer" target="_blank">Responsible Gambling Code of Conduct</a>
      &nbsp;and for South Australian residents by the&nbsp;
      <a className="rg-link" href="https://www.betfair.com.au/info/pdf/SA-GCoP.pdf" target="_blank" rel="noopener noreferrer">South Australian Responsible Gambling Code of Practice</a>
      .
    </p>
    <p>
    BetStop is the Australian National Self-Exclusion Register. For more information, please visit&nbsp;
        <a className="rg-link" href="https://betstop.gov.au/" rel="noopener noreferrer" >https://betstop.gov.au</a>
    </p>

    <p className="ssc-regbox">
    What are you prepared to lose today? Set a deposit limit. For free and confidential support call 1800&nbsp;858&nbsp;858 or visit&nbsp;
      <a 
        href="https://www.gamblinghelponline.org.au/"
        target="_blank" 
        rel="noopener noreferrer"
        className="ssc-link"
      >
      gamblinghelponline.org.au
      </a>
    </p>
    <div className="ssc-flkh">
      <ul className="ssc-flnk" style={{ listStyle: "none" }}>
        <li className="ssc-lw responsibleGambling "><a href="https://www.betfair.com.au/info/responsible-gambling/" className=" ssc-gamblingCanBeAddictive" target="_blank" rel="noopener noreferrer" title="Responsible Gambling" data-gtml="gamblingCanBeAddictive">Responsible Gambling</a></li>
        <li className="ssc-lw "><a href="https://www.betfair.com/AUS_NZL/aboutUs/Terms.and.Conditions/" className="sscmw width(1000) height(600) autoscroll(true) ssc-termsAndConditions" target="_popup" title="Terms &amp; Conditions" data-gtml="termsAndConditions">Terms &amp; Conditions</a></li>
        <li className="ssc-lw "><a href="https://www.betfair.com/aboutUs/Privacy.Policy/" className="sscmw width(1000) height(600) autoscroll(true) ssc-privacyPolicy" target="_popup" title="Privacy Policy" data-gtml="privacyPolicy">Privacy Policy</a></li>
        <li className="ssc-lw "><a href="https://www.betfair.com/aboutUs/Cookie.Policy/" className="sscmw width(1000) height(600) autoscroll(true) ssc-cookiePolicy" target="_popup" title="Cookie Policy" data-gtml="cookiePolicy">Cookie Policy</a></li>
        <li className="ssc-lw "><a href="https://www.betfair.com/en/aboutUs/Rules.and.Regulations/" className="sscmw width(1000) height(600) autoscroll(true) ssc-rulesAndRegulations" target="_popup" title="Rules &amp; Regulations" data-gtml="rulesAndRegulations">Rules &amp; Regulations</a></li>
        <li className="ssc-lw last"><a href="https://www.betfair.com/AUS_NZL/aboutUs/Dispute.Resolution/ " className="sscmw width(1000) height(600) autoscroll(true) ssc-disputeResolution" target="_popup" title="" data-gtml="disputeResolution">Dispute Resolution</a></li>
      </ul>
    </div>
  </div>
);

export default Regs;